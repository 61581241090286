<template>
  <div id="map">
    <l-map :zoom="zoom" :center="center">
      <l-tile-layer
          :url="url"
          :attribution="attribution">
      </l-tile-layer>

      <l-marker :lat-lng="markerLatLng" ></l-marker>
    </l-map>
  </div>
</template>

<script>
import { LMap, LTileLayer,LMarker} from 'vue3-leaflet';
// import 'leaflet/dist/leaflet.css';

export default {
  name: 'mv_leaflet',
  components: {
    LMap,
    LTileLayer,
    LMarker
  },
  data() {
    return {
      // center: [59.32399,24.56759],
      // markerLatLng: [59.32399,24.56759]
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
      zoom: 20,
      center: [59.32399,24.56759],
      markerLatLng: [59.32399,24.56759]
    };
  },
};
</script>

<style>
#map {
  height: 15em;
  width: auto;
}
</style>
