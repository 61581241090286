<template>
  <mv_menu/>
  <mv_carousel/>
  <mv_modal/>
  <mv_body/>
</template>

<script>
import mv_menu from './components/mv_menu.vue'
import mv_carousel from './components/mv_carousel.vue'
import mv_modal from './components/mv_modal.vue'
import mv_body from './components/mv_body.vue'

export default {
  name: 'App',
  components: {
    mv_menu,
    mv_modal,
    mv_carousel,
    mv_body,
  }
}
</script>

<style type="scss">
#app {
  --bs-body-font-family:  'Roboto', Helvetica, Arial, sans-serif;
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
  scroll-behavior: smooth;
}
</style>
