<template>
  <h1 class="m-4 text-success" id="pakkumine">
     Tava küsimus kes teeb, kus teha korda ? <p>Meie teeme !</p>
  </h1>
  <div class="container mt-5 text-bg-light p-3" tabindex="0">
    <h4>
      Kuidas kujuneb teenuse hind.
    </h4>
    <p>
      Meil ei ole tunnitasu ! <br/>
      Teenustöö hinna kujunemine sõltub mitmetest teguritest,
      nagu näiteks teenuse liik,
      töö maht ja keerukus, ajakulu,
      kasutatavad materjalid ja töö tegemiseks vajalikud oskused ning kogemused.
      Töö maht ja keerukus võivad samuti teenustöö hinna mõjutada.
      Näiteks võib suurema projekti puhul hind olla kõrgem kui väiksema projekti puhul.
      Samuti võib keerukama projekti puhul kuluda rohkem aega ja seega võib hind kujuneda kõrgemaks.
        Kokkuvõttes sõltub teenustöö hind mitmetest erinevatest teguritest ning see
      võib varieeruda sõltuvalt teenuse liigist, töö mahust ja keerukusest, ajakulust, kasutatavatest materjalidest
      ning meie oskustest ja kogemustest.
      Helista (+372 5662 6080) küsi pakkumist, muidu teada ei saa.
    </p>
    </div>
   <div class="container mt-5 text-bg-light p-3 mb-5" tabindex="0">
    <h4 id="asukoht">
        Meie asukoht
    </h4>
    <p>
      Aadress: Sooja 5a, Saue, 76505 Harju maakond
    </p>
     <mv_leaflet/>
  </div>
 </template>

<script>
import mv_leaflet from "@/components/mv_leaflet";

export default {
  name: "mv_body",
  components: {
    mv_leaflet
  }
}
//https://www.geeksforgeeks.org/bootstrap-5-navbar-scrolling/
</script>

<style type="scss">

.container {
  background-color: #e7ecf0 !important;
  border-radius: 1em;
}
</style>
